window.$ = window.jQuery = require('jquery');
window._ = require('lodash');
window.axios = require('axios');
require('jquery-placeholder');
require('slick-carousel');
require('jquery-inview');

$('input, textarea').placeholder();

//パスワード表示機能
$('.j-btnPasswordDisplay').on('click', function () {
  const $self = $(this);
  const $password = $self.prev('input');
  if ($self.toggleClass('is-hide').hasClass('is-hide')) {
    $password.attr('type', 'password');
  } else {
    $password.attr('type', 'text');
  }
});

//ログインIDにメールアドレスを使用するチェックボックス
$('.j-isEmailLoginForm').on('change', function () {
  const $self = $(this);
  const $form = $('.j-loginIdForm');
  if ($self.prop('checked')) {
    $form.val($form.data('value')).prop('disabled', true);
  } else {
    $form.val('').prop('disabled', false);
  }
});

//チェックボックス
$('.j-integrateRadioBox').on('click', function () {
  const $self = $(this);
  $('.j-integrateRadioBox').removeClass('is-active');
  $self.addClass('is-active');
  const $loginMethodForm = $self.find('input[name=login_method]').prop('checked', true);

  $('input[name=login_id]').prop('disabled', $loginMethodForm.val() !== 'dlsite');
  $('input[name=password]').prop('disabled', $loginMethodForm.val() !== 'dlsite');
});

// ヘルプ開閉
$('.j-qaListBox-item').on('click', function () {
  $(this).parent('dl').toggleClass('is-opened');
});

// ハッシュ値を指定された場合は該当ヘルプを開いた状態で表示
if (location.href.indexOf('guide/help') !== -1) {
  var hash = location.hash.slice(1);
  $('[id=' + hash + ']').children('dl').toggleClass('is-opened');
}

// dlsiteアカウントとは スライダー
$('.j-HeroPhoto').slick({
  dots: false,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 5000,
  speed: 700,
  fade: true,
  cssEase: 'linear',
  arrows: false,
});

// サービス紹介
$('.j-AnimationSv').on('inview', function (event, isInView) {
  if (isInView) {
    $(this).stop().addClass('animationSv-read');
  } else {
    $(this).stop().removeClass('animationSv-read');
  }
});

// メールアドレス入力ボックスを開くボタン
$('.j-openEmailInputBox').on('click', function (event) {
  $('.j-emailInputBox').toggleClass('is-opened');
  return false;
});

// 空メール送信ボタン
$('.j-sendBlankEmail').on('click', function (event) {
  setTimeout(function(){
    $('.j-dialog').show();
  }, 2000);
});

// ダイアログ閉じるボタン
$('.j-dialogClose').on('click', function(event){
  $('.j-dialog').hide();
  return false;
});

// サブミットボタン不活性化
$('.j-openLoading').on('click', function (event) {
  const target  = event.target;
  const $form   = $(target).closest('form');
  const $self = $(this);

  // ローディング画面表示
  $('.p-loading').show();
  // submitを無効化
  $self.prop('disabled', true);

  // submit実施
  $form.submit();
});

// メール受信設定
$('.j-reject-mail').on('click', function(event){
  const $target = $(event.currentTarget);
  axios.post($target.data('endpoint'))
    .then(function(){
      $target.find('.j-item').toggleClass('on');
    });
});
